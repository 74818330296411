
import React from 'react';
import { Link } from 'gatsby';
import { Helmet } from 'react-helmet';

const WaterWars = () => {
  return (
    <div className="container">
      <Helmet>
        <title> The Shoyaright! </title>
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@zuffshoya" />
        <meta name="twitter:title" content="The Water Wars" />
        <meta name="twitter:image" content="http://shoya.co/static/shoyaright_icon_twitter_card_large_summary-ce9c728db92ba633cc195662666f7a8f.png" />
        <meta name="twitter:image:alt" content="The Shoyaright! Logo" />
      </Helmet>
      <link rel='stylesheet' href='../styles/global.css' />
      <div className="article">
        <Link to="/shoyaright">
          <div className="shoyarightChomskyArticle" />
        </Link>
        <div className="articleTitle">
          <p>The Water Wars</p>
        </div>
        <div className="byLine">
          <p> By: Lil Shoyaright! (Pub. Jun. 19, 2021) </p>
        </div>
        <div className="articleBody">
         <p>
           I've been watching YouTube continuously since 2006. I've been down triple the number of rabbit holes that the average person has. And I'm not usually the one to make statements as bold as this one I'm about to make, but with all that context in mind: I'm about to share with y'all what I know is the <i>worst video I've ever seen on </i><a href="https://www.youtube.com/watch?v=3vuy9kAqgp8">YouTube</a>.
         </p>
         <p>
           I mean this shit is insultingly bad in every way. Obama spent $525B on the military the year this dropped, and they couldn't spare the cost of one standard-issue rifle for some quality propaganda?
         </p>
         <p>
           The pics is ASS, the effects cheesy as shit, and they just super wrong for abusing that beautiful upbeat Mother-Africa music lmaoooo like who tf is y'all tricking with that????? Not to mention the disrespectful usage of that proverb at the beginning like what????????????
         </p>
         <p>
           Naw and they got a section in this jawn called 'Deter and Defeat Transnational Threats' and my guess is they never seen Christopher Nolan's <i>Memento</i> (2000). If that reference didn't land smh but just picture the double Spiderman pointing meme in that case.
         </p>
         <p>
           But the worst part to me might be at 4:10 -- of course I'm talking about the stack of guns next to boxes that read 'HALAL'... On my humble authority as a Muslim, I don't agree that endless war-for-profit is halal. You'd get to heaven quicker with swine on ya tine fr.
         </p>
         <p>
           I gotta give out some honorable mentions to the folks holding it down in the comment section though:
         </p>
         <p>
           <span className="highlight">Niger Maurice</span> says "Hey look guys we've just been occupied" <br />(8 years ago) <br />
           <span className="highlight">A.m Girl</span> says "Leave us alone." <br />(6 years ago) <br />
           <span className="highlight">Devonte Swang</span> says "Ok but y'all wanna leave tho 👀"<br /> (2 weeks ago) <br />
         </p>
         <p>
           Anyway I know the news cycle moves a mile a minute now, but I just wanna resurface <a href="https://www.youtube.com/watch?v=t833cvVNXpg">this clip</a> of Kamala Harris from a couple months ago where she plants the seed about water scarcity conflicts, and tie this all up with a bow. And by tie it up with a bow I mean imma just link to the <a href="https://twitter.com/babadookspinoza/status/1382327985097822210?s=20">Twitter thread</a> where I first seen somebody spell it out lol.
         </p>
         <p>
           But yeah naw so for all the Obamala stans out there who prolly ain't even reading this, fuck y'all. The zeitgeist done got way too oversaturated with that shallow ass representation politics bullshit man smh get over it!!!!!!!!!!!!!!!! AND STRAP UP LMAO.
         </p>
         <p>
           And on that exact note: Happy Juneteenth.
         </p>
        </div>
        <p className="plug">If you enjoyed this article, consider <a href="https://www.patreon.com/join/shoyaright?">becoming a patron</a> or making a <a href="https://www.paypal.com/cgi-bin/webscr?cmd=_donations&business=zuff@shoya.co&lc=US&item_name=The%20Shoyaright%21&currency_code=USD&no_note=0&bn=PP-DonationsBF:btn_donateCC_LG.gif:NonHostedGuest">one-time pledge</a>. Support corporate journalism!</p>
        <div className="articleCopyright">
          <p>&copy; Shoya, Inc. 2021</p>
        </div>
      </div>
    </div>

  );
};
export default WaterWars;